<template>
  <sequential-entrance fromBottom>
    <v-container fluid>
      <v-row>
        <v-spacer></v-spacer>
        <v-col class="d-flex" cols="12" sm="1">
          <v-tooltip bottom color="success">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="componentDialog = !componentDialog"
                v-bind="attrs"
                v-on="on"
                right
                fab
                small
                color="success white--text"
                style="margin: 5px"
              >
                <v-icon>mdi-clipboard-plus-outline</v-icon>
              </v-btn>
            </template>
            <span>Add New Target</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" md="4" sm="4" 
            v-for="component in component_list"
            :key="component">
          <div
            style="padding: 10px"
          >


          <v-card
              class="mx-auto my-12"
              max-width="100%"
            >
                <v-card-text>
                <h3>{{component.name}}</h3>
                </v-card-text>
              <v-divider class="mx-4"></v-divider>

              <v-card-text>
                <v-chip-group
                  v-model="selection"
                  active-class="green accent-4 white--text"
                  column
                >
                  <v-chip><v-icon small>mdi-plus</v-icon> Add Indicator</v-chip>

                  <v-chip link to="/dtpme/target_indicators"><v-icon small>mdi-eye</v-icon> View Indicators</v-chip>

                </v-chip-group>
              </v-card-text>
          </v-card>
          </div>
        </v-col>
      </v-row>

      <v-dialog v-model="componentDialog" max-width="60%">
        <v-card>
          <v-card-title><v-icon>mdi-plus</v-icon> New Target</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
          <v-textarea
            outlined
            name="input-5-3"
            label="Describe Briefly About the Target"
          ></v-textarea>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="pink darken-1"
              class="white--text"
              small
              @click="componentDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="success darken-1"
              small
              class="white--text"
              @click="componentDialog = false"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </sequential-entrance>
</template>

<script>
export default {
  name: "DashBoard",
  data: () => ({
    hovered: true,
    overlay: false,
    componentDialog: false,
    detail: "/components/details/",
    sample: 1,
    component_list: [
      { name: "IDA-19 Access Indicator. Percentage of the population covered by a mobile broadband network signal (3G, 4G or higher)", id: 1 },
      { name: "Number of Government Ministries, Departments and Agencies benefitting from broadband internet service", id: 2 },
      { name: "Individuals (aged 15 and above) using the internet, per 100 inhabitants, of which percentage female.", id: 3 },
      { name: "Number of monthly transactions accessing a public service via internet or amobile phone.", id: 4 }
    ],
  }),
};
</script>




